import { render, staticRenderFns } from "./Glossary.vue?vue&type=template&id=748371bc"
import script from "./Glossary.vue?vue&type=script&lang=js"
export * from "./Glossary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Lines: require('/opt/buildhome/repo/components/Lines.vue').default})
