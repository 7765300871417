export default (context, inject) => {
    const i18n = context.app.i18n;
    const translate = (key, fallback, obj) => {
        if (obj === undefined && typeof (fallback) === 'object') {
            obj = fallback;
            fallback = undefined;
        }
        if (i18n.te(key)) {
            const translated = obj ? i18n.t(key, obj) : i18n.t(key);
            if (translated) {
                return translated;
            }
        }
        if (fallback !== undefined) {
            return fallback;
        }
        if (obj) {
            key = key.replace(/\{([^\}]*)\}/g, (match, word) => obj[word] !== undefined ? obj[word] : '');
        }
        return key;
    };
    inject("tf", translate);
};