
export default {
    data() {
        return {
            language: this.$store.state.user.language,
            accessibe: this.$store.state.user.accessibe,
            autoClose: false,
            matchTags: false,
            lineWrapping: true,
            minimap: false,
            autoFocus: true,
            theme: "default",
            accountChanged: false,
            editorChanged: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    watch: {
        accessibe() {
            this.accountChanged = true;
        },
        language() {
            this.accountChanged = true;
        },
        autoClose() {
            this.editorChanged = true;
        },
        matchTags() {
            this.editorChanged = true;
        },
        lineWrapping() {
            this.editorChanged = true;
        },
        autoFocus() {
            this.editorChanged = true;
        },
        theme() {
            this.editorChanged = true;
        },
        minimap() {
            this.editorChanged = true;
        }
    },
    methods: {
        save() {
            const el = this.$refs.saveAccount;
            el.disabled = true;
            el.innerHTML = this.$tf("Saving...");
            this.$axios
                .$put("/account", { language: this.language, accessibe: this.accessibe })
                .then(() => {
                    this.accountChanged = false;
                    const user = { ...this.$store.state.user };
                    user.language = this.language;
                    user.accessibe = this.accessibe;
                    this.$i18n.setLocale(user.language);
                    window.history.replaceState("", "", this.switchLocalePath(user.language));
                    this.$store.commit("user", user);
                    el.innerHTML = this.$tf("Saved!");
                    this.$nuxt.$emit("switchLang", this.language)
                    window.location.reload();
                })
                .catch(err => {
                    console.error(err);
                    this.$bvModal.msgBoxOk(this.$tf("An error occurred while saving account settings"));
                })
                .then(() => {
                    setTimeout(() => {
                        el.innerHTML = this.$tf("Save");
                        el.disabled = false;
                    }, 3000);
                });
        },
        refresh() {
            window.location.href = `${process.env.LOGIN_URL}?next=${this.$route.path}`;
        },
        updateEditorSettings() {
            localStorage.setItem(
                "editorSettings",
                JSON.stringify({
                    autoClose: this.autoClose,
                    matchTags: this.matchTags,
                    lineWrapping: this.lineWrapping,
                    minimap: this.minimap,
                    autoFocus: this.autoFocus,
                    theme: this.theme === "oceanic-next" ? "mbo" : this.theme
                })
            );
            const el = this.$refs.saveEditor;
            if (el) {
                el.disabled = true;
                el.innerHTML = this.$tf("Saved!");
                setTimeout(() => {
                    el.innerHTML = this.$tf("Save editor settings");
                    el.disabled = false;
                }, 3000);
            }
            this.$nuxt.$emit("updateEditorSettings");
            this.editorChanged = false;
        },
        resetColumns() {
            localStorage.removeItem(`widths-${this.user.id}`);
            localStorage.removeItem(`columns-${this.user.id}`);
            this.$nuxt.$emit("resetColumns", true);
        },
        linkAccount(kind) {
            const url = `${process.env.SS_URL}/account/${kind}.link`;
            window.open(url, "_blank");
        },
        updateSettings() {
            const settings = JSON.parse(localStorage.getItem("editorSettings"));
            if (settings) {
                this.autoClose = settings.autoClose;
                this.matchTags = settings.matchTags;
                this.lineWrapping = settings.lineWrapping;
                this.minimap = settings.minimap;
                this.autoFocus = settings.autoFocus;
                this.theme = settings.theme === "oceanic-next" ? "mbo" : settings.theme;
            }
            this.$nextTick(function () {
                this.editorChanged = false;
            });
        }
    },
    mounted() {
        const settings = JSON.parse(localStorage.getItem("editorSettings"));
        if (settings) {
            this.autoClose = Boolean(settings.autoClose);
            this.matchTags = Boolean(settings.matchTags);
            this.lineWrapping = Boolean(settings.lineWrapping);
            this.minimap = Boolean(settings.minimap);
            this.autoFocus = Boolean(settings.autoFocus);
            this.theme = settings.theme;
        }
        this.$nextTick(function () {
            this.editorChanged = false;
        });

        this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
            if (modalId === "settings_modal" && this.editorChanged) {
                const warn = window.confirm("You have unsaved changes to your settings. Leave anyway?");
                if (!warn) {
                    bvEvent.preventDefault();
                } else {
                    this.editorChanged = false;
                }
            }
        });
    }
};
