
const ORDER = {
    checkpoint: 1,
    quiz: 2,
    challenges: 3,
    puzzles: 4,
    scenes: 5,
    blockspro: 6,
    games: 7
};
export default {
    props: {
        variant: {
            type: String,
            default: "primary"
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        lesson() {
            return this.$store.state.currentLesson || {};
        },
        currentActivityOrder() {
            const route = this.$route.name;
            if (route.includes("checkpoint")) return ORDER.checkpoint;
            if (route.includes("quiz")) return ORDER.quiz;
            if (route.includes("challenge")) return ORDER.challenges;
            if (route.includes("puzzle")) return ORDER.puzzles;
            if (route.includes("scene")) return ORDER.scenes;
            if (route.includes("blockspro")) return ORDER.blockspro;
            if (route.includes("game")) return ORDER.games;
            return 0;
        },
        next() {
            if (this.lesson.checkpoint && this.currentActivityOrder === 0) {
                return {
                    kind: this.$tf("code checkpoint"),
                    url: `/code/${this.lesson.id}/checkpoint`,
                    text: this.$tf("Go to checkpoint"),
                    icon: "code"
                };
            }
            if (this.lesson.quiz && this.currentActivityOrder === 1) {
                return {
                    kind: this.$tf("quiz"),
                    url: `/code/${this.lesson.id}/quiz`,
                    text: this.$tf("Go to lesson quiz"),
                    icon: "format_list_bulleted"
                };
            }
            if (this.lesson.challenges && this.lesson.challenges.length && [1, 2].includes(this.currentActivityOrder)) {
                return {
                    kind: this.$tf("challenges"),
                    url: `/code/${this.lesson.id}/challenges`,
                    text: this.$tf("Go to challenges"),
                    icon: "emoji_objects"
                };
            }
            if (this.lesson.puzzles && this.lesson.puzzles.length && [1, 2, 3].includes(this.currentActivityOrder)) {
                return {
                    kind: this.$tf("puzzles"),
                    url: `/code/${this.lesson.id}/puzzles`,
                    text: this.$tf("Go to puzzles"),
                    icon: "extension"
                };
            }
            if (this.lesson.scenes && this.lesson.scenes.length && [1, 2, 3, 4].includes(this.currentActivityOrder)) {
                return {
                    kind: this.$tf("blocks"),
                    url: `/code/${this.lesson.id}/scenes`,
                    text: this.$tf("Go to challenges"),
                    icon: "nature_people"
                };
            }
            if (
                this.lesson.blockspro_challenges &&
                this.lesson.blockspro_challenges.length &&
                [1, 2, 3, 4, 5].includes(this.currentActivityOrder)
            ) {
                return {
                    kind: this.$tf("blocks pro"),
                    url: `/code/${this.lesson.id}/blockspro`,
                    text: this.$tf("Go to challenges")
                };
            }
            if (
                this.lesson.games &&
                this.lesson.games.length &&
                [1, 2, 3, 4, 5, 6].includes(this.currentActivityOrder)
            ) {
                return {
                    kind: this.$tf("games"),
                    url: `/code/${this.lesson.id}/games`,
                    text: this.$tf("Go to games"),
                    icon: "games"
                };
            }
            return {};
        }
    },
    methods: {
        navigateTo(url) {
            this.$router.push(url);
            this.$store.commit("ui/checkpointButton", true);
        }
    }
};
