export const state = () => ({
    glossary: {},
    glossaryLastUpdated: 0,
    glossaryLang: 'en'
});
export const mutations = {
    glossary(state, glossary) {
        state.glossaryLastUpdated = Date.now();
        state.glossaryLang = glossary.lang;
        state.glossary = glossary.data;
    }
};