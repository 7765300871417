// [Workbox](https://developer.chrome.com/docs/workbox/what-is-workbox) is a set of modules that simplifies service worker usage.
// Workbox Window docs: https://developer.chrome.com/docs/workbox/modules/workbox-window
export default async ({ app, store }, inject) => {
    const workbox = await window.$workbox;

    if (!workbox) {
        console.error("Workbox couldn't be loaded.");
    } else {
        workbox.addEventListener('installed', event => {
            if (event.isUpdate) {
                window.$nuxt.$bvToast.show("update-toast");

            }
        });

        workbox.addEventListener('message', event => {
            const data = event.data;
            if (data.type === 'AWAITING_INPUT') {
                store.commit('pyodide/addAwaitingInput', { id: data.id, prompt: data.prompt });
            }
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                store.commit('pyodide/setServiceWorkerReady', true);
                workbox.messageSW({ type: 'PY_REGISTER' });
            });
        } else {
            console.error('Service workers are not supported.');
        }
        inject('wb', workbox);
    }
};
