
import Paragraph from "@/components/render/Paragraph.vue";
import Header from "@/components/render/Header.vue";
import List from "@/components/tiptaprender/List.vue";
import Image from "@/components/render/Image.vue";
import Code from "@/components/render/Code.vue";
import Video from "@/components/render/Video.vue";
import HorizontalRule from "@/components/render/HorizontalRule.vue";
import Quote from "@/components/render/Quote.vue";
import Table from "@/components/tiptaprender/Table.vue";
import Reveal from "@/components/render/Reveal.vue";

export default {
    props: {
        block: Object
    },
    components: {
        Paragraph: Paragraph,
        Header: Header,
        List: List,
        Picture: Image,
        Code: Code,
        Video: Video,
        HorizontalRule: HorizontalRule,
        Quote: Quote,
        Table: Table,
        Reveal: Reveal
    },
    data() {
        return {
            blockDetails: {},
            blockType: ""
        };
    },
    beforeMount() {
        this.getType(this.block);
    },
    methods: {
        applyMark(mark, text) {
            const type = mark.type;
            if (type === "bold") {
                text = `<strong>${text}</strong>`;
            } else if (type === "italic") {
                text = `<i>${text}</i>`;
            } else if (type === "underline") {
                text = `<u>${text}</u>`;
            } else if (type === "code") {
                text = `<code class="inline-code">${text}</code>`;
            } else if (type === "link") {
                text = `<a href="${mark.attrs.href}" target="_blank">${text}</a>`;
            } else if (type === "highlight") {
                text = `<mark>${text}</mark>`;
            }
            return text;
        },
        getText(line, escape = false) {
            let text = escape ? line.text.replace(/</g, "&lt;").replace(/>/g, "&gt;") : line.text;
            if (line.marks) {
                line.marks.forEach(mark => {
                    text = this.applyMark(mark, text);
                });
            }
            return text;
        },
        renderContent(content, escape = false) {
            let text = "";
            if (content) {
                content.forEach(line => {
                    if (line.type === "text") {
                        text += this.getText(line, escape);
                    } else if (!line.content) {
                        this.getType(line);
                    } else {
                        text += this.renderContent(line.content, escape);
                    }
                });
            }
            return text;
        },
        renderList(block) {
            this.blockDetails = {
                items: block.content,
                style: block.type === "orderedList" ? "ordered" : ""
            };
            this.blockType = "List";
        },
        renderImage(block) {
            this.blockDetails = {
                url: block.attrs.src,
                alt: block.attrs.alt,
                title: block.attrs.title
            };
            this.blockType = "Picture";
        },
        renderVideo(block) {
            this.blockDetails = {
                url: block.attrs.src,
                alt: block.attrs.alt,
                title: block.attrs.title,
                subtitle: block.attrs.subtitle,
                english: block.attrs.english,
                spanish: block.attrs.spanish
            };
            this.blockType = "Video";
        },
        renderHeader(block) {
            const text = this.renderContent(block.content, true);
            this.blockDetails = {
                text: text,
                level: block.attrs.level
            };
            this.blockType = "Header";
        },
        renderParagraph(block) {
            const text = this.renderContent(block.content, true);
            if (text) {
                this.blockDetails = {
                    text: text
                };
                this.blockType = "Paragraph";
            }
        },
        renderQuote(block) {
            const text = this.renderContent(block.content, true);
            this.blockDetails = {
                text: text
            };
            this.blockType = "Quote";
        },
        renderCodeBlock(block) {
            const text = this.renderContent(block.content);
            this.blockDetails = {
                code: text,
                copy: block.attrs.copy,
                preview: block.attrs.preview,
                hide: block.attrs.hide,
                pseudo: block.attrs.pseudo
            };
            this.blockType = "Code";
        },
        renderHorizontalRule(block) {
            this.blockDetails = {};
            this.blockType = "HorizontalRule";
        },
        renderTable(block) {
            const rows = [];
            block.content.forEach(row => {
                const item = [];
                row.content.forEach(cell => {
                    item.push({
                        type: cell.type,
                        attrs: cell.attrs,
                        content: cell.content
                    });
                });
                rows.push(item);
            });
            this.blockDetails = { rows: rows };
            this.blockType = "Table";
        },
        renderReveal(block) {
            const text = this.renderContent(block.content, true);
            this.blockDetails = { text: text };
            this.blockType = "Reveal";
        },
        getType(block) {
            if (block.type === "bulletList" || block.type === "orderedList") {
                this.renderList(block);
            } else if (block.type === "heading") {
                this.renderHeader(block);
            } else if (block.type === "paragraph") {
                this.renderParagraph(block);
            } else if (block.type === "blockquote") {
                this.renderQuote(block);
            } else if (block.type === "codeBlock") {
                this.renderCodeBlock(block);
            } else if (block.type === "image") {
                this.renderImage(block);
            } else if (block.type === "video") {
                this.renderVideo(block);
            } else if (block.type === "horizontalRule") {
                this.renderHorizontalRule(block);
            } else if (block.type === "table") {
                this.renderTable(block);
            } else if (block.type === "reveal") {
                this.renderReveal(block);
            } else {
                console.warn(`Unsupported block type: ${block.type}`);
            }
        }
    }
};
