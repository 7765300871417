
export default {
    name: "folder",
    props: ["folder", "active", "path"],
    data() {
        return {
            open: false
        };
    },
    computed: {
        files() {
            return this.folder.files.filter(o => !o.name.endsWith("temp"));
        },
        folderPath() {
            if (this.path) {
                return `${this.path}${this.folder.name}/`;
            }
            return this.folder.name + "/";
        }
    },
    methods: {
        selectFile(id) {
            this.$nuxt.$emit("selectFile", id);
        },
        newFile() {
            this.$nuxt.$emit("showFileModal", { path: this.folderPath });
        },
        editFile(file) {
            this.$nuxt.$emit("showFileModal", file);
        },
        newFolder() {
            this.$nuxt.$emit("showFolderModal", { path: this.folderPath });
        },
        editFolder() {
            this.$nuxt.$emit("showFolderModal", { name: this.folder.name, path: this.path, edit: true });
        }
    }
};
