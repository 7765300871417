import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=3addce0a"
import script from "./simple.vue?vue&type=script&lang=js"
export * from "./simple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VoyageLogo: require('/opt/buildhome/repo/components/VoyageLogo.vue').default,Notification: require('/opt/buildhome/repo/components/Notification.vue').default,Glossary: require('/opt/buildhome/repo/components/Glossary.vue').default,ColorPicker: require('/opt/buildhome/repo/components/ColorPicker.vue').default,Help: require('/opt/buildhome/repo/components/Help.vue').default,CanvasComplete: require('/opt/buildhome/repo/components/CanvasComplete.vue').default})
