
export default {
    data() {
        return {
            message: "",
            search: "",
            email: "",
            show: false,
            disabled: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        pageHasTutorial() {
            return this.$store.state.hasTutorial;
        }
    },
    methods: {
        startTutorial() {
            this.show = false;
            this.$nuxt.$emit("tutorial");
        },
        sendHelp() {
            const form = {
                email: this.user.email || this.email,
                message: this.message,
                url: this.$route.fullPath,
                currentLesson: `${this.$store.state.currentLesson.id}: ${this.$store.state.currentLesson.name}`,
                currentProject: `${this.$store.state.currentProject.id}: ${this.$store.state.currentProject.name}`,
                workingFile: `${localStorage.getItem("workingFile")}: ${localStorage.getItem("workingFileName")}`,
                code: window.editor && window.editor.getValue()
            };
            this.disabled = true;
            this.$axios
                .post(`/support`, form)
                .then(() => {
                    this.message = "";
                    this.show = false;
                    alert(
                        this.$tf(
                            "Our support team has received your message and will reply via email as soon as possible!"
                        )
                    );
                })
                .catch(err => {
                    console.error(err.response);
                    alert(err.response.data);
                })
                .then(() => {
                    this.disabled = false;
                });
        },
        searchKnowledgeBase() {
            const url = "https://support.skillstruck.com/knowledge/kb-search-results?term=" + this.search;
            window.open(url, "_blank");
        }
    }
};
