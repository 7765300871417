
export default {
    data() {
        return {
            search: "",
            show: false
        };
    },
    computed: {
        glossary() {
            return this.$store.state.localStorage.glossary;
        },
        active() {
            return this.$store.state.ui.glossary;
        }
    },
    watch: {
        active(value) {
            if (value) {
                this.show = true;
            }
        },
        show(value) {
            if (!value) {
                this.$store.commit("ui/glossary");
            }
        }
    },
    methods: {
        filter(item) {
            return `${item.title} ${item.definition}`.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
        }
    }
};
