
import "@lottiefiles/lottie-player";
export default {
    props: {
        data: Object
    },
    data() {
        return {
            showPlay: true,
            showIntro: false,
            showOutro: false,
            showReplay: false
        }
    },
    methods: {
        playVideo() {
            this.showReplay = false;
            this.showPlay = false;
            this.showOutro = false;
            this.showIntro = true;
            setTimeout(() => {
                this.showIntro = false;
                const video = document.getElementById("video");
                video.addEventListener("ended", this.playOutro, false);
                video.controls = true;
                video.play();
            }, 3500);
        },
        playOutro() {
            this.showOutro = true;
            setTimeout(() => {
                this.showReplay = true;
            }, 3000);
        },
        getDefault(lang){
            return this.$store.state.user.language == lang
        }
    },
    destroyed() {
        const video = document.getElementById("video");
        if (video) video.removeEventListener("ended", this.playOutro);
    }
};
