
export default {
    props: ["code"],
    data() {
        return {
            mode: null,
            html: "",
            display: false,
            srOnlyContainer: ""
        };
    },
    computed: {
        unique() {
            return `preview-${this.uid || "temp"}`;
        },
        stage() {
            return process.env.STAGE;
        },
        files() {
            return this.$store.state.files;
        }
    },
    methods: {
        buildIframe() {
            const iframe = document.createElement("iframe");
            iframe.id = this.unique;
            iframe.classList.add("h-100");
            iframe.classList.add("w-100");
            iframe.setAttribute("frameborder", "0");
            return iframe;
        },
        absolutePath(filename, link) {
            if (!filename.indexOf("/") == -1) {
                return link;
            }
            let parts = filename.split("/");
            parts.pop();
            // go back as many directories as needed
            let re = /\.\.\//g;
            const steps = ((link || "").match(re) || []).length;
            for (var i = 0; i < steps; i++) {
                parts.pop();
                link = link.replace("../", "");
            }
            const path = parts.length ? parts.join("/") + "/" : "";
            return path + link;
        },
        linkAllFiles(filename) {
            const newFiles = [];
            const files = this.$store.state.files.filter(o => o.ext == "html");
            files.forEach(file => {
                newFiles.push({
                    name: file.name,
                    content: this.linkFiles(file.content, file.name, false, true)
                });
            });
            let textareaHtml = '<div style="display: none">';
            for (let i = 0; i < newFiles.length; i++) {
                newFiles[i].content = '<div id="bodydiv">' + newFiles[i].content + "</div>";
                textareaHtml += `<textarea id="${newFiles[i].name.replace(".", ":")}">${
                    newFiles[i].content
                }</textarea>`;
            }
            textareaHtml += "</div>";
            textareaHtml +=
                "<sc" +
                "ript type=\"text/javascript\">const parent = {load_preview: (path) => {const div = document.getElementById('bodydiv');\n const el = document.getElementById(path.replace('.',':'));\n div.innerHTML = el.value.replace(/<body/, '<div id=\"body\"').replace(/<\\/body>/, '</div>');\n}}</sc" +
                "ript>";
            textareaHtml += "<style> div#body {position: absolute; top:0; bottom: 0; left: 0; right:0;}</style>";
            for (let i = 0; i < newFiles.length; i++) {
                newFiles[i].content += textareaHtml;
            }
            return newFiles
                .find(o => o.name === filename)
                .content.replace(/<body/, '<div id="body"')
                .replace(/<\/body>/, "</div>");
        },
        linkFiles(html, filename, noScript = false, newTab = false) {
            const LINK_REG = /<link[^>]+?href[^>]+?["'](.+?)["'][^<]*?>/gm;
            const SCRIPT_REG = /<script[^>]+?src[^>]+?["'](.+?)["'][^<]*?>/gm;
            const ANCHOR_REG = /<a[^>]+?(href[^>]+?['"](.+?html.*?)['"])/gm;
            const ALL_ANCHORS = /<a[^>]+?(href=['\"](?!https?:\/\/|#file).*?['\"])/gm;
            // get CSS links
            let match;
            if (!html) {
                html = "";
            }
            let text = html;
            while ((match = LINK_REG.exec(text)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === LINK_REG.lastIndex) {
                    LINK_REG.lastIndex++;
                }
                const link = match[0];
                const path = this.absolutePath(filename, match[1]);
                const file = this.$store.state.files.find(o => o.name === path);
                if (file) {
                    const css = `<style>${file.content}</style>`;
                    html = html.replace(link, css);
                }
            }
            text = html;
            while ((match = SCRIPT_REG.exec(text)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === SCRIPT_REG.lastIndex) {
                    SCRIPT_REG.lastIndex++;
                }
                const link = match[0];
                const path = this.absolutePath(filename, match[1]);
                const file = this.$store.state.files.find(o => o.name === path);
                if (file) {
                    const script = `<script type="text/javascript">${file.content}<\/script>`;
                    html = html.replace(link, script);
                }
            }
            text = html;
            while ((match = ANCHOR_REG.exec(text)) !== null) {
                const path = this.absolutePath(filename, match[2]);
                const notLinked = path.charAt(0) === "/";
                let destination = "";
                if (newTab) {
                    destination = notLinked
                        ? 'href="" onclick="return false;"'
                        : `href="#file" onclick="parent.load_preview('${path}')"; return false;"`;
                } else {
                    destination = notLinked
                        ? 'href="" onclick="return false;"'
                        : `href="#file" onclick="window.parent.postMessage({type: 'load_preview_internal', previewId: '${this.unique}', path: '${path}'}, '*'); return false;"`;
                }
                html = html.replace(match[1], destination);
            }
            while ((match = ALL_ANCHORS.exec(text)) !== null) {
                // prevent incorrectly formatted links from loading
                html = html.replace(match[1], match[1] + ' onclick="return false;"');
            }
            // remove all script tags if running with noScript
            if (noScript) {
                html = html.replace(/<script.*?<\/script>/gs, "");
            }
            return html;
        },
        getModuleNames() {
            // seperate out the module name so that it can be checked against a file name later
            const moduleNames = [];
            const getModuleName = /^import\s+(\w+)|^from\s+(\w+)\s+import|\s+as\s+(\w+)$/;
            for (const file of this.importStatements) {
                const match = file.match(getModuleName);
                if (match) {
                    const moduleName = match[1] || match[2] || match[3];
                    moduleNames.push(moduleName);
                }
            }
            return moduleNames;
        },
        matchModuleToFile(moduleNames) {
            // if a module name matches a file name, add the file to the modules array
            this.files.forEach(file => {
                moduleNames.forEach(module => {
                    if (file.name === `${module}.py` && !this.modules.some(o => o.name === `${module}.py`)) {
                        let name = "";
                        let found = false;
                        this.importStatements.forEach(s => {
                            // renames the module if import is used with a different name, ex: 'import game as g'
                            if (s.includes(`import ${module} as`)) {
                                let index = s.indexOf(" as ");
                                if (index !== -1) {
                                    name = s.slice(index + 4);
                                }
                                found = true;
                            }
                        });
                        if (!found) {
                            name = `${module}`;
                        }
                        // modules array contains the file and the name the user assigned to the module
                        this.modules.push({ moduleFile: file, fileName: name });
                    }
                });
            });
            return this.modules;
        },
        getModules(code, existingImports) {
            if (existingImports) {
                this.importStatements = [...existingImports];
                const moduleNames = this.getModuleNames();
                this.modules = this.matchModuleToFile(moduleNames);
            }
            if (this.modules && this.modules.length > 0) {
                // if an import statement matches a file name, it is removed from code and placed into a try except statement below otherwise an error is rasied
                this.importStatements.forEach(s => {
                    this.modules.forEach(module => {
                        // if the import does not match a file name, it is left as is in the code
                        if (s.includes(module.moduleFile.name.slice(0, -3))) {
                            code = code.replace(s, "");
                        }
                    });
                });
                // Create a the try except to try to import modules that match file names & update globals to contain the name and file of the module if cannot be imported
                let imports = "import sys\n";
                let trys = "try:\n";
                let excepts = "except ImportError:\n";
                let tryImports = "",
                    exceptImports = "",
                    createObjects = "",
                    contents = "",
                    updateGlobals = "";
                this.modules.forEach(module => {
                    tryImports += `\timport ${module.fileName}\n`;
                    exceptImports += `\t${module.fileName} = None\n`;
                    createObjects += `${module.fileName} = type(sys)('${module.fileName}')\n`;
                    contents += `${module.moduleFile.content}\n`;
                    updateGlobals += `${module.fileName}.__dict__.update(globals())\n`;
                });
                this.importCode =
                    imports + trys + tryImports + excepts + exceptImports + createObjects + contents + updateGlobals;
            }
            return this.importCode, code;
        },
        runJS(code) {
            code = "var __iter__=0;\n" + code;
            code = code.replace(
                /((while|for)\s*\(.+?\)\s*\{)/g,
                "$1if(__iter__>=6170){alert('Infinite loop detected');break;}__iter__++;"
            );
            code = `const __src__='${code
                .replace(/\\/g, "\\\\")
                .replace(/'/g, "\\'")
                .replace(/\r\n/g, "\n")
                .replace(/\n/g, "' + '\\n' + '")}';`;
            code += "try {eval(__src__);}catch(e){console.error(e);}";
            let js =
                "<style>html{background-color:#F2F2F2;}body{margin:0px;padding:20px 0px;}p{padding:10px 20px;margin:0px;border:1px solid transparent;}.error{background-color:#FEF0F0;color:#FF0100;}.log{border-color:#F2F2F2;}.warn{border-color:#FEF5C1;background-color:#FEFBE5;color:#5B3C01;}</style>";
            js +=
                '<body><div style="font-family:monospace; border-bottom: 1px solid lightgray; background-color:#fff;" id="console_div"></div><script type="text/javascript">';
            js +=
                'var log = document.querySelector("#console_div");["log", "warn", "error"].forEach(function (verb) {console[verb] = (function (method, verb, log) {return function () {method.apply(this, arguments);let newText = Array.prototype.join.call(arguments, " ").replace(/\\n/g, "<br>");let msg = document.createElement("p");msg.classList.add(verb);msg.innerHTML = newText;log.appendChild(msg);};})(console[verb].bind(console), verb, log);});';
            js += `<\/script><script type="text/javascript">(function(){${code}})();<\/script><script>document.body.scrollTop=document.body.scrollHeight;<\/script></body>`;
            return js;
        },
        runPY(code, filename) {
            this.display = false;
            if (code.indexOf(".txt") < 0) {
                this.modules = [];
                this.importCode = "";
                // checks for any import statements
                const checkForImports = /^(?:import|\s*from\s+\w+\s+import).*/gm;
                const existingImports = code.match(checkForImports);
                if (existingImports) {
                    this.importCode, (code = this.getModules(code, existingImports));
                }
                const space = /^\t/m.exec(code) ? "\t" : "    ";
                code = "globals()['__iter__']=0\n" + code;
                code = code.replace(
                    /^([\t ]*)((while|for).+?:)$/gm,
                    `$1$2\n$1${space}globals()['__iter__']+=1\n$1${space}if __iter__>6170:\n$1${
                        space + space
                    }__alert__()\n$1${space + space}break`
                );
                this.mode = "pyodide";
                this.$parent.mode = this.mode;
                if (this.$refs.terminal === undefined) {
                    this.$nuxt.$on("pythonready", () => {
                        if (this.importCode) {
                            this.$refs.terminal.execute(code, true, this.importCode, filename);
                            this.$nuxt.$off("pythonready");
                        } else {
                            this.$refs.terminal.execute(code, true, this.importCode, filename);
                            this.$nuxt.$off("pythonready");
                        }
                    });
                } else {
                    if (this.importCode) {
                        this.$refs.terminal.execute(code, true, this.importCode, filename);
                    } else {
                        this.$refs.terminal.execute(code, true, this.importCode, filename);
                    }
                }
            } else {
                this.mode = "python";
                this.$parent.mode = "python";
                this.executeCode(code);
            }
        },
        runGame(code) {
            this.mode = "pygame";
            this.$parent.mode = "pygame";
            this.display = true;
            if (this.$refs.display !== undefined && this.$refs.display.rfb !== null) {
                this.$refs.display.focus();
            }
            this.executeCode(code);
        },
        runJava(code, filename, config) {
            this.mode = "java";
            this.$parent.mode = "java";
            this.executeCode(code, filename, config);
        },
        runCpp(code, filename, config) {
            this.mode = "cpp";
            this.$parent.mode = "cpp";
            this.executeCode(code, filename, config);
        },
        executeCode(code, filename = "", config) {
            if (this.$refs.terminal === undefined) {
                this.$nuxt.$on("terminal_ready", () => {
                    this.$nextTick(() => {
                        this.$refs.terminal.execute(code, filename, config);
                    });
                    this.$nuxt.$off("terminal_ready");
                });
            } else {
                if (!this.$refs.terminal.socket) {
                    this.$nuxt.$on("terminal_ready", () => {
                        this.$nextTick(() => {
                            this.$refs.terminal.execute(code, filename, config);
                        });
                        this.$nuxt.$off("terminal_ready");
                    });
                } else {
                    this.$nextTick(() => {
                        this.$refs.terminal.execute(code, filename, config);
                    });
                }
            }
        },
        async showPreview(html = "", file, newTab, noScript = false, config) {
            this.srOnlyContainer = "";
            if (this.mode) {
                this.mode = null;
                this.$parent.mode = this.mode;
            }
            if (!file) {
                file = this.$store.state.files.find(o => o.name === html);
                if (!file) {
                    return;
                }
                html = file.content;
            }
            if (file.ext === "css") {
                const name = file.name && file.name.replace(".css", ".html");
                const htmlFile = this.$store.state.files.find(o => o.name === name);
                if (htmlFile) {
                    file = htmlFile;
                    html = file.content;
                } else {
                    html = `<html><head><style>${html}</style></head><body><div id="main"><h1 tabindex="0">Hello World!</h1></div></body></html>`;
                }
            } else if (file.ext === "js") {
                if (noScript) return false;
                html = this.runJS(html);
            } else if (file.ext === "py") {
                if (noScript) return false;
                if (file.content && file.content.indexOf("pygame") >= 0) {
                    return this.runGame(html);
                } else if (file.content && file.content.indexOf("import turtle") >= 0) {
                    return this.runGame(html);
                } else {
                    return this.runPY(html, file.name);
                }
            } else if (file.ext === "java") {
                if (noScript) return false;
                return this.runJava(html, file.name, config);
            } else if (file.ext === "cpp") {
                if (noScript) return false;
                return this.runCpp(html, file.name, config);
            }
            if (file.name) {
                html = this.linkFiles(html, file.name, noScript);
            }
            const iframe = this.buildIframe();
            while (this.$refs.iframeContainer.firstChild) {
                this.$refs.iframeContainer.removeChild(this.$refs.iframeContainer.firstChild);
            }
            this.$refs.iframeContainer.appendChild(iframe);
            if (!iframe) {
                return html;
            }
            iframe.srcdoc = html;
            this.$nuxt.$emit("code_shown");
            if (newTab) {
                if (file.ext === "html") {
                    this.newTab(this.linkAllFiles(file.name));
                } else if (file.ext === "js") {
                    this.newTab(this.runJS(file.content));
                }
            }
            return html;
        },
        newTab(html) {
            const tab = window.open("about:blank");
            tab.document.open();
            tab.document.write(html);
            tab.document.close();
        },
        resetTerminal() {
            this.$refs.terminal.reset();
        },
        resizeTerminal() {
            this.$refs.terminal && this.$refs.terminal.resize();
        },
        updateSrContainer(data) {
            this.srOnlyContainer = data;
        },
        onMessage(event) {
            const { type, previewId, path } = event.data;
            if (type === "load_preview_internal" && previewId === this.unique) {
                this.showPreview(path);
            }
        }
    },
    mounted() {
        window.addEventListener("message", this.onMessage);
        this.$nuxt.$on("terminalUpdated", this.updateSrContainer);
    },
    beforeDestroy() {
        this.$nuxt.$off("terminalUpdated");
        window.removeEventListener("message", this.onMessage);
    }
};
