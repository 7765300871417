
export default {
    data() {
        return {
            urlToConvert: null,
            convertedUrl: null,
            previewUrl: null,
            customImages: [],
            showCopied: false,
            imageAccessible: true,
            perPage: 18,
            currentPage: 1
        };
    },
    watch: {
        urlToConvert() {
            this.convertedUrl = null;
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        convertUrl() {
            const url = this.urlToConvert;
            const regex = /^https:\/\/drive.google.com\/file\/d\/(.+?)\/.*$/;
            if (regex.test(url)) {
                const id = url.match(regex)[1];
                this.convertedUrl = `https://drive.google.com/uc?export=view&id=${id}`;
            } else {
                this.convertedUrl = this.urlToConvert;
            }
        },
        imageRestricted() {
            this.imageAccessible = false;
            this.$error(`You need to update permissions for this image in Google Drive`);
        },
        saveImage() {
            if (!this.customImages.includes(this.convertedUrl)) {
                this.customImages = [...this.customImages, this.convertedUrl];
                this.$saveLocal(`customImages-${this.user.id}`, this.customImages);
            } else {
                this.$info("You've already saved this image.");
            }
        },
        removeImage(url) {
            const index = this.customImages.indexOf(url);
            if (index != -1) {
                this.customImages.splice(index, 1);
                this.$saveLocal(`customImages-${this.user.id}`, this.customImages);
            }
        }
    },
    mounted() {
        this.customImages = this.$getLocal(`customImages-${this.user.id}`) || [];
    }
};
