
export default {
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        viewGlossary() {
            const glossary = this.$store.state.localStorage.glossary;
            const glossaryLastUpdated = this.$store.state.localStorage.glossaryLastUpdated;
            const glossaryLang = this.$store.state.localStorage.glossaryLang;
            if (
                !Object.keys(glossary).length ||
                (glossaryLastUpdated && Date.now() - glossaryLastUpdated > 604800000) ||
                (glossary && !glossaryLastUpdated) ||
                glossaryLang !== this.user.language
            ) {
                this.$axios.$get("/glossary").then(data => {
                    this.$store.commit("localStorage/glossary", { data: data, lang: this.user.language });
                });
            }
        }
    }
};
