
export default {
    props: {
        data: Object
    },
    data() {
        return {
            showPreview: false,
            shown: false,
            isPseudo: false,
            disabledTryButton: false
        };
    },
    computed: {
        language() {
            return this.$store.state.currentLesson.language;
        }
    },
    methods: {
        viewPseudo() {
            this.isPseudo = !this.isPseudo;
            if (this.isPseudo) {
                this.$refs.editor.setCode(this.data.pseudo);
            } else {
                this.$refs.editor.setCode(this.data.code);
            }
        },
        toPreview(code) {
            const preview = this.$refs.preview;
            if (preview) {
                preview.showPreview(
                    this.isPseudo ? this.data.code : code,
                    { ext: this.language, name: "textbook.css" },
                    false
                );
                this.$nextTick(() => {
                    const el = this.$refs.previewContainer;
                    el && el.scrollIntoView({ behavior: "smooth", block: "center" });
                });
            }
            return code;
        },
        loadPreview() {
            this.showPreview = true;
            this.disabledTryButton = true;
            this.$refs.editor.getCode();
        },
        copyCode() {
            this.$refs.editor.copy();
            const el = this.$refs.copyButton;
            el.disabled = true;
            el.innerHTML = this.$tf("Copied!");
            setTimeout(() => {
                el.innerHTML = this.$tf("Copy");
                el.disabled = false;
            }, 2000);
        },
        showCode() {
            this.shown = true;
        },
        preventCopy(e) {
            e.preventDefault();
            return false;
        },
        preventCut(e) {
            e.preventDefault();
            return false;
        },
        preventDrag(e) {
            e.preventDefault();
            return false;
        }
    },
    mounted() {
        if (!this.data.hide) {
            this.shown = true;
        }
        this.$nuxt.$on("refreshEditors", () => {
            this.$refs.editor.refresh();
        });
        this.$nuxt.$on("code_shown", () => {
            this.disabledTryButton = false;
        });
        if (!this.data.copy) {
            this.$refs.editor.$el.addEventListener("copy", this.preventCopy, true);
            this.$refs.editor.$el.addEventListener("cut", this.preventCut, true);
            this.$refs.editor.$el.addEventListener("dragstart", this.preventDrag);
        }
    },
    beforeDestroy() {
        this.$nuxt.$off("refreshEditors");
        this.$nuxt.$off("code_shown");
        if (!this.data.copy) {
            this.$refs.editor.$el.removeEventListener("copy", this.preventCopy);
            this.$refs.editor.$el.removeEventListener("cut", this.preventCut);
            this.$refs.editor.$el.removeEventListener("dragstart", this.preventDrag);
        }
    }
};
