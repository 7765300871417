
export default {
    data() {
        return { show: false };
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    mounted() {
        this.$nuxt.$on("canvasComplete", () => {
            this.show = true;
        });
    },
    beforeDestroy() {
        this.$nuxt.$off("canvasComplete");
    }
};
