
export default {
    data() {
        return { show: false };
    },
    computed: {
        lessons() {
            return this.$store.state.lessons_flat;
        },
        currentLesson() {
            const id = this.$route.params.lesson;
            return this.$store.state.lessons[id] || {};
        },
        nextLesson() {
            const id = Number(this.$route.params.lesson);
            const index = this.lessons.findIndex(o => o.id === id);
            if (index + 1 < this.lessons.length) {
                return this.lessons[index + 1];
            }
            return null;
        }
    },
    methods: {
        navigateNextLesson() {
            this.show = false;
            this.$router.push(`/code/${this.nextLesson.id}`);
            this.$store.commit("ui/checkpointButton", true);
        },
        async loadLessons() {
            if (!this.$store.state.courses.length) {
                await this.$axios.$get("/directory").then(data => {
                    this.$store.commit("courses", data);
                });
            }
        }
    },
    mounted() {
        this.$nuxt.$on("lessonComplete", () => {
            this.$refs.modal.show();
            this.$store.commit("completeLesson", this.$route.params.lesson);
        });
    },
    beforeDestroy() {
        this.$nuxt.$off("lessonComplete");
    }
};
