export default async function ({ app, store }, inject) {
    inject('pyodide', {
        async sendInput({ id = '', value = '' }) {
            app.$wb.messageSW({
                type: 'PY_INPUT',
                id,
                value
            });
            store.commit('pyodide/removeAwaitingInput', { id });
        },
        removeInputs({ id = '' }) {
            store.commit('pyodide/removeAwaitingInputsForInstance', { id });
        }
    });
}
