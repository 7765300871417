
export default {
    props: {
        file: {
            type: Object,
            default: () => {
                return {};
            }
        },
        active: Number
    }
};
