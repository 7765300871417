export const state = () => ({
    user: {},
    courses: [],
    lessons: {},
    lessons_flat: [],
    currentLesson: {},
    currentCourse: {},
    showLesson: true,
    currentProject: {},
    projects: [],
    files: [],
    currentFile: {},
    lessonProgress: {},
    checkpointProgress: null,
    challengeProgress: { challenges: {} },
    gameProgress: { games: {} },
    puzzleProgress: { puzzles: {} },
    sceneProgress: { scenes: {} },
    blocksProProgress: { blockspro: {} },
    presentationProgress: { presentations: {} },
    profile: null,
    messages: [],
    plans: [],
    currentPlan: {},
    currentActivity: null,
    hasTutorial: false,
    assessments: [],
    assessmentProgress: {},
    examsAdded: false,
    customAssessments: [],
    customAssessmentsLoaded: false,
    customAssessmentProgress: {},
    submissions: [],
    codeSocket: null,
    images: [],
    savedPasscodes: {}
});

const getLessons = courses => {
    const out = [];
    courses.forEach(course => {
        const course_id = course.id;
        const course_name = course.name;
        const units = course.units;
        units.forEach(unit => {
            const unit_id = unit.id;
            const unit_name = unit.name;
            const lessons = unit.lessons;
            lessons.forEach(lesson => {
                out.push({
                    id: lesson.id,
                    name: lesson.name,
                    unit: unit_name,
                    course: course_name
                });
            });
        });
    });
    return out;
};

const compare = (a, b) => {
    a = a.name.toUpperCase();
    b = b.name.toUpperCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export const mutations = {
    user(state, user) {
        state.user = user;
    },
    points(state, points) {
        state.user.points += points;
        state.user = { ...state.user };
    },
    courses(state, courses) {
        state.courses = courses;
        state.lessons_flat = getLessons(courses);
    },
    lessons(state, lessons) {
        state.lessons = lessons;
        state.currentLesson = {};
    },
    lesson(state, lesson) {
        state.lessons[lesson.id] = lesson;
        state.currentLesson = lesson;
    },
    currentLesson(state, items) {
        state.currentLesson = items;
    },
    projects(state, projects) {
        state.projects = projects;
    },
    project(state, project) {
        const index = state.projects.findIndex(o => o.id === project.id);
        if (index >= 0) {
            state.projects[index] = project;
        } else {
            state.projects.unshift(project);
        }
        state.projects = [...state.projects];
    },
    removeProject(state, project) {
        const index = state.projects.findIndex(o => o.id === project.id);
        if (index >= 0) {
            state.projects.splice(index, 1);
            state.projects = [...state.projects];
        }
    },
    liveProject(state, project) {
        const index = state.projects.findIndex(o => o.is_live === true);
        const updated = state.projects.findIndex(o => o.id === project.id);
        if (index >= 0) {
            state.projects[index].is_live = false;
        }
        state.projects[updated].is_live = true;
        state.projects = [...state.projects];
    },
    showLesson(state, active) {
        state.showLesson = active;
    },
    toggleShowLesson(state, active) {
        if (active !== undefined) {
            state.showLesson = active;
        } else {
            state.showLesson = !state.showLesson;
        }
    },
    currentProject(state, project) {
        state.currentProject = project;
        state.files = project.files || [];
    },
    file(state, file) {
        const index = state.files.findIndex(o => o.id === file.id);
        if (file.id && index >= 0) {
            state.files[index] = file;
        } else {
            state.files.push(file);
            state.files.sort(compare);
        }
        state.files = [...state.files];
    },
    removeFile(state, file) {
        const index = state.files.findIndex(o => o.id === file.id);
        state.files.splice(index, 1);
        state.files = [...state.files];
    },
    savedFiles(state) {
        state.files.forEach(file => {
            delete file.changed;
        });
        state.files = [...state.files];
    },
    lessonProgress(state, progress) {
        state.lessonProgress = { ...state.lessonProgress, ...progress };
    },
    completeLesson(state, lessonId) {
        if (!state.lessonProgress[lessonId]) {
            state.lessonProgress = { [lessonId]: true, ...state.lessonProgress };
        }
        if (state.checkpointProgress && state.checkpointProgress.id == lessonId) {
            state.checkpointProgress.finished = true;
        }
    },
    profile(state, profile) {
        state.profile = profile;
    },
    messages(state, messages) {
        state.messages = messages;
    },
    addMessage(state, message) {
        state.messages.push(message);
        state.messages = [...state.messages];
    },
    checkpointProgress(state, progress) {
        state.checkpointProgress = progress;
    },
    challengeProgress(state, progress) {
        state.challengeProgress = progress;
    },
    updateChallengeProgress(state, challenge) {
        state.challengeProgress.challenges[challenge] = true;
        state.challengeProgress = { ...state.challengeProgress };
        if (state.checkpointProgress && !state.checkpointProgress.challenges) {
            state.checkpointProgress.challenges = [challenge];
        } else if (state.checkpointProgress && !state.checkpointProgress.challenges.includes(challenge)) {
            state.checkpointProgress.challenges.push(challenge);
        }
    },
    gameProgress(state, progress) {
        state.gameProgress = progress;
    },
    updateGameProgress(state, gameId) {
        state.gameProgress.games[gameId] = true;
        state.gameProgress = { ...state.gameProgress };
        if (state.checkpointProgress && !state.checkpointProgress.games) {
            state.checkpointProgress.games = [gameId];
        } else if (state.checkpointProgress && !state.checkpointProgress.games.includes(gameId)) {
            state.checkpointProgress.games.push(gameId);
        }
    },
    puzzleProgress(state, progress) {
        state.puzzleProgress = progress;
    },
    updatePuzzleProgress(state, puzzleId) {
        state.puzzleProgress.puzzles[puzzleId] = true;
        state.puzzleProgress = { ...state.puzzleProgress };
        if (state.checkpointProgress && !state.checkpointProgress.puzzles) {
            state.checkpointProgress.puzzles = [puzzleId];
        } else if (state.checkpointProgress && !state.checkpointProgress.puzzles.includes(puzzleId)) {
            state.checkpointProgress.puzzles.push(puzzleId);
        }
    },
    presentationProgress(state, progress) {
        state.presentationProgress = progress;
    },
    updatePresentationProgress(state, presentationId) {
        state.presentationProgress.presentations[presentationId] = true;
        state.presentationProgress = { ...state.presentationProgress };
        if (state.checkpointProgress && !state.checkpointProgress.presentation) {
            state.checkpointProgress.presentation = [presentationId];
        } else if (state.checkpointProgress && !state.checkpointProgress.presentation.includes(presentationId)) {
            state.checkpointProgress.presentation.push(presentationId);
        }
    },
    plans(state, plans) {
        state.plans = plans;
    },
    addPlan(state, plan) {
        const index = state.plans.findIndex(o => o.id === plan.id);
        if (index < 0) {
            state.plans.push(plan);
        } else {
            state.plans[index] = plan;
        }
        state.plans = [...state.plans];
    },
    currentPlan(state, plan) {
        state.currentPlan = plan;
    },
    currentActivity(state, activity) {
        state.currentActivity = activity;
    },
    completeActivity(state) {
        const activity = state.currentActivity;
        const plan = state.currentPlan;
        if (activity && plan) {
            if (!plan.progress) {
                plan.progress = {};
            }
            if (['challenges', 'games', 'puzzles'].includes(activity.kind)) {
                plan.progress[activity.id] = (plan.progress[activity.id] || 0) + 1;
            } else {
                plan.progress[activity.id] = true;
            }
        }
    },
    currentCourse(state, course) {
        state.currentCourse = course;
    },
    hasTutorial(state, hasTutorial) {
        state.hasTutorial = hasTutorial;
    },
    assessments(state, assessments) {
        state.assessments = assessments;
    },
    assessmentProgress(state, progress) {
        state.assessmentProgress = progress;
    },
    updateLessonsInUnit(state, lessons) {
        const course = { ...state.courses.find(c => c.id == lessons.course) };
        const unit = course.units.find(u => u.id === lessons.unit);
        unit.lessons = lessons.lessons;
        unit.changed = true;
        const index = state.courses.findIndex(c => c.id === course.id);
        if (index !== -1) {
            state.courses[index] = course;
        }
        state.courses = [...state.courses];
    },
    examsAdded(state, added) {
        state.examsAdded = added;
    },
    removeExams(state) {
        const courses = [...state.courses];
        courses.forEach(c => {
            const units = [...c.units];
            units.forEach(u => {
                const lessons = u.lessons.filter(o => !o.exam);
                u.changed = false;
                u.lessons = lessons;
            });
            c.units = units;
        });
        state.assessments = [];
        state.courses = courses;
        state.examsAdded = false;
    },
    submissions(state, submissions) {
        state.submissions = state.submissions.concat(submissions);
        state.submissions = [...state.submissions];
    },
    updateSubmission(state, submission) {
        const index = state.submissions.findIndex(o => o.activity_type == submission.activity_type && o.activity_id == submission.activity_id);
        state.submissions[index] = submission;
        state.submissions = [...state.submissions];
    },
    codeSocket(state, socket) {
        state.codeSocket = socket;
    },
    sceneProgress(state, progress) {
        state.sceneProgress = progress;
    },
    updateSceneProgress(state, sceneId) {
        state.sceneProgress.scenes[sceneId] = true;
        state.sceneProgress = { ...state.sceneProgress };
        if (state.checkpointProgress && !state.checkpointProgress.scenes) {
            state.checkpointProgress.scenes = [sceneId];
        } else if (state.checkpointProgress && !state.checkpointProgress.scenes.includes(sceneId)) {
            state.checkpointProgress.scenes.push(sceneId);
        }
    },
    blocksProProgress(state, progress) {
        state.blocksProProgress = progress;
    },
    updateBlocksProProgress(state, blocksProId) {
        state.blocksProProgress.blockspro[blocksProId] = true;
        state.blocksProProgress = { ...state.blocksProProgress };
        if (state.checkpointProgress && !state.checkpointProgress.blockspro_challenges) {
            state.checkpointProgress.blockspro_challenges = [blocksProId];
        } else if (state.checkpointProgress && !state.checkpointProgress.blockspro_challenges.includes(blocksProId)) {
            state.checkpointProgress.blockspro_challenges.push(blocksProId);
        }
    },
    getImages(state, images) {
        state.images = images;
    },
    customAssessments(state, assessments) {
        state.customAssessments = assessments;
        state.customAssessmentsLoaded = true;
    },
    customAssessmentProgress(state, progress) {
        state.customAssessmentProgress = progress;
    },
    currentFile(state, file) {
        state.currentFile = { ...file };
    },
    savedPasscodes(state, data) {
        if (!state.savedPasscodes[data.lesson]) {
            state.savedPasscodes[data.lesson] = data.passcode;
        }
        state.savedPasscodes = { ...state.savedPasscodes };
    }
};

// load user data into the store
export const actions = {
    nuxtServerInit({ commit }, { req }) {
        if (req.user) {
            commit('user', req.user);
        }
    }
};