
export default {
    props: ["points"],
    data() {
        return {
            visible: false,
            amount: this.points,
            stats: null,
            newProgress: false
        };
    },
    methods: {
        show(activityDetails) {
            const { points, stats, newProgress } = activityDetails;
            {
                if (points) {
                    this.amount = points;
                } else {
                    this.amount = null;
                }
                if (stats) {
                    this.stats = stats;
                } else {
                    this.stats = null;
                }
                if (newProgress) {
                    this.newProgress = newProgress;
                } else {
                    this.newProgress = null;
                }
                this.visible = true;
                setTimeout(() => {
                    const el = document.getElementById("notification");
                    if (el) {
                        el.classList.remove("appear");
                        setTimeout(() => {
                            this.visible = false;
                        }, 1000);
                    }
                }, 5000);
                this.$store.commit("completeActivity");
            }
        }
    },
    mounted() {
        this.$nuxt.$on("notification", this.show);
    }
};
