export default (context, inject) => {
    inject('saveLocal', (key, data) => {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error(e);
            localStorage.clear();
            localStorage.setItem(key, JSON.stringify(data));
        }
    });
    inject('getLocal', key => {
        try {
            const data = JSON.parse(localStorage.getItem(key));
            if (Date.now() > data.exp) {
                throw new Error("Expired");
            }
            return data;
        } catch (e) {
            return null;
        }
    });
    inject('clearLocal', key => {
        localStorage.removeItem(key);
    });
    inject('success', message => {
        window.$nuxt.$bvToast.toast(message, {
            title: context.app.$tf("Success"),
            variant: "success",
            solid: true,
        });
    });
    inject('info', message => {
        window.$nuxt.$bvToast.toast(message, {
            title: context.app.$tf("Info"),
            variant: "info",
            solid: true,
            noAutoHide: true
        });
    });
    inject('error', message => {
        window.$nuxt.$bvToast.toast(message, {
            title: context.app.$tf("Error"),
            variant: "danger",
            solid: true,
            noAutoHide: true
        });
    });
    inject('copy', async text => {
        try {
            await navigator.clipboard.writeText(text).then(() => true);
            localStorage.setItem('copiedValue', JSON.stringify(text));
        } catch (e) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.top = '0';
            textarea.style.left = '0';
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            const result = document.execCommand('copy');
            document.body.removeChild(textarea);
            return result;
        }
    });
};