
export default {
    props: ["activity"],
    data() {
        return {
            form: {
                current: "",
                new_password: "",
                confirm_password: ""
            }
        };
    },
    methods: {
        reset() {
            this.form.current = "";
            this.form.new_password = "";
            this.form.confirm_password = "";
        },
        update() {
            this.$refs.submit.disabled = true;
            this.$axios
                .$post("/password", { form: this.form })
                .then(() => {
                    this.$refs.modal.hide();
                })
                .catch(err => {
                    console.error(err);
                    console.error(err.response);
                    alert(err.response.data);
                    this.$refs.submit.disabled = false;
                });
        }
    }
};
