/*
Each component has built in this._uid, but it's reserved for internal use.
This plugin is a workaround to assign a unique ID to each component without using
the reserved _uid. Check the links for more details:
- https://stackoverflow.com/questions/34950867/vue-js-how-to-set-a-unique-id-for-each-component-instance
- https://github.com/vuejs/vue/issues/5886
- https://www.npmjs.com/package/vue-unique-id
*/
import Vue from 'vue';

Vue.use(Vue => {
    let uid = 1;
    Vue.mixin({
        beforeCreate: function () {
            this.uid = uid.toString();
            uid += 1;
        }
    });
});