
export default {
    props: ["error"],
    middleware({ app, error }) {
        // insert middleware here
    },
    beforeMount() {
        if (this.error.statusCode === 401) {
            const path = this.$route.path;
            // redirect immediately
            // window.location.href = `/login?next=${path}`;
        }
    },
    methods: {
        login() {
            window.location.href = `${process.env.LOGIN_URL}?next=${this.$route.path}&test=${Boolean(
                this.$store.state.user.test
            )}`;
        },
        changeSection() {
            window.location.href = `${process.env.SS_URL}/account/sections?platform=v2`;
        }
    }
};
