
export default {
    props: {
        content: {
            type: String,
            default: ""
        },
        delimiter: {
            type: String,
            default: "\n"
        }
    }
};
