export const state = () => ({
    checkpointButton: true,
    topRightNav: false,
    glossary: false
});

export const mutations = {
    checkpointButton(state, active) {
        state.checkpointButton = active;
    },
    topRightNav(state, active) {
        state.topRightNav = active;
    },
    glossary(state) {
        state.glossary = !state.glossary;
    }
};