export default ({ app, store }) => {
    const gaUid = !store.state.user.id ? false : store.state.user.id;

    // who knows what's going on here. see https://nuxtjs.org/faq/ga/
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r
            ; (i[r] =
                i[r] ||
                function () {
                    ; (i[r].q = i[r].q || []).push(arguments)
                }),
                (i[r].l = 1 * new Date())
            ; (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.src = g
        m.parentNode.insertBefore(a, m)
    })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
    );

    ga('create', process.env.GA_ID, 'auto');

    app.router.afterEach((to, from) => {

        if (gaUid) {
            ga('set', 'dimension1', gaUid);
        }
        ga('set', 'dimension2', JSON.stringify(new Date().getTime() / 1000));
        ga('set', 'page', to.fullPath);
        ga('send', 'pageview');
    });

    window.ga = ga;
}