import { render, staticRenderFns } from "./FileButton.vue?vue&type=template&id=da6fe7fe&scoped=true"
import script from "./FileButton.vue?vue&type=script&lang=js"
export * from "./FileButton.vue?vue&type=script&lang=js"
import style0 from "./FileButton.vue?vue&type=style&index=0&id=da6fe7fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6fe7fe",
  null
  
)

export default component.exports