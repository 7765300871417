import Vue from 'vue';
export const state = () => ({
    serviceWorkerReady: false,
    awaitingInputs: {}
});

export const mutations = {
    setServiceWorkerReady(state, ready) {
        state.serviceWorkerReady = ready;
    },
    addAwaitingInput(state, input) {
        if (state.awaitingInputs[input.id]) {
            state.awaitingInputs[input.id].push(input.prompt);
        } else {
            Vue.set(state.awaitingInputs, input.id, [input.prompt]);
        }
    },
    removeAwaitingInput(state, input) {
        if (state.awaitingInputs[input.id] && state.awaitingInputs[input.id].length) {
            state.awaitingInputs[input.id].splice(0, 1);
        }
    },
    removeAwaitingInputsForInstance(state, input) {
        state.awaitingInputs[input.id] = [];
        state.awaitingInputs = { ...state.awaitingInputs, [input.id]: [] };
    }
};