
export default {
    data() {
        return {
            profile: {}
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        projects() {
            return this.$store.state.projects;
        },
        ss_url() {
            return process.env.SS_URL;
        }
    },
    methods: {
        logout() {
            window.location.href = process.env.LOGOUT_URL;
        },
        load() {
            if (!this.$store.state.profile && !this.user.onlyCode) {
                this.$models.Profile.get()
                    .then(data => {
                        this.$store.commit("profile", data);
                        this.profile = data;
                    })
                    .catch(err => {
                        console.error("Error loading profile", err);
                        this.$error(this.$tf("Error loading profile"));
                    });
            }
        }
    }
};
