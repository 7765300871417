
export default {
    data() {
        return {
            loading: false,
            content: []
        };
    },
    computed: {
        library() {
            const library = { items: {} };
            if (this.content) {
                this.content.forEach(o => {
                    let current = library;
                    const parts = o.Key.split("/").filter(x => x);
                    const name = [...parts].filter(p => p).pop();
                    const isFile = /\.[0-9a-z]+$/.test(name);
                    const item = isFile
                        ? {
                              id: o.Key,
                              name: name,
                              ext: name.split(".").pop()
                          }
                        : { id: o.Key, name: name, items: {} };
                    for (let i = 0; i < parts.length; i++) {
                        const path = parts[i];
                        if (i === parts.length - 1) {
                            current.items[path] = item;
                            continue;
                        }
                        if (current.items[path]) {
                            current = current.items[path];
                            continue;
                        }
                        current.items[path] = item;
                        current = current.items[path];
                    }
                });
            }
            return library;
        }
    },
    watch: {
        library() {
            setTimeout(() => {
                this.loading = false;
            }, 400);
        }
    },
    methods: {
        getResources() {
            this.loading = true;
            this.content = this.$getLocal("sslibrary");
            if (!this.content) {
                this.$axios
                    .$get("/library")
                    .then(content => {
                        this.content = content;
                        this.$saveLocal("sslibrary", content);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$error((err.response && err.response.data) || this.$tf("Could not load resources"));
                        this.$bvModal.hide("library_modal");
                    });
            }
        }
    }
};
