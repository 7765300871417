import * as Sentry from "@sentry/vue";

export default function ({ req, route, redirect, $axios, store, error }) {
    if (route.path === '/login') {
        return;
    }
    if (req && !req.user) {
        return redirect('/login');
    }
    if (!store.state.user.id) {
        if (store.state.user.id === undefined) {
            return $axios.$get('/user').then(user => {
                store.commit('user', user);
                Sentry.setUser({ id: user.id, sectionId: user.section && user.section.id || null });
                return;
            }).catch(() => {
                store.commit('user', { id: false });
                return error({ statusCode: 401 });
            });
        }
        return redirect('/login');
    }
}