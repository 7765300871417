
export default {
    props: {
        data: Object
    },
    computed: {
        text() {
            const text = this.data.text || "";
            return text;
        }
    }
};
