
export default {
    props: {
        data: Object
    },
    data() {
        return {
            visible: false
        };
    }
};
