
import "codemirror/lib/codemirror.css";
import "codemirror/theme/mbo.css";
import "codemirror/theme/shadowfox.css";
import "codemirror/addon/dialog/dialog.css";
var CodeMirror;
if (process.browser) {
    CodeMirror = require("codemirror");
    require("codemirror/mode/javascript/javascript");
    require("codemirror/mode/htmlmixed/htmlmixed");
    require("codemirror/mode/xml/xml");
    require("codemirror/mode/css/css");
    require("codemirror/mode/python/python");
    require("codemirror/addon/edit/matchbrackets");
    require("codemirror/addon/edit/matchtags");
    require("codemirror/addon/comment/comment");
    require("codemirror/addon/display/placeholder");
    require("codemirror/addon/edit/closetag");
    require("codemirror/addon/edit/closebrackets");
    require("codemirror/addon/search/search");
    require("codemirror/addon/search/searchcursor");
    require("codemirror/addon/search/jump-to-line");
    require("codemirror/addon/dialog/dialog");
}

export default {
    props: {
        mode: String,
        placeholder: String,
        preview: Boolean,
        text: String,
        ext: String,
        wrap: {
            type: Boolean,
            default: undefined
        },
        resize: {
            type: Boolean,
            default: false
        },
        allowsettings: {
            type: Boolean,
            default: true
        },
        allowcopy: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editor: null,
            file: {},
            showExit: false,
            lastChanged: 0
        };
    },
    watch: {
        ext(ext) {
            this.editor.setOption("mode", this.getMode(ext));
        }
    },
    methods: {
        focusRef(ref) {
            this.$refs[ref] && this.$refs[ref].focus();
        },
        getCode(num) {
            if (!this.editor && num !== false) {
                setTimeout(() => {
                    this.getCode(false);
                }, 100);
                return;
            }
            const code = this.editor.getValue();
            this.$emit("code", code);
            return code;
        },
        setCode(code) {
            this.editor.setValue(code);
        },
        getEditor() {
            return this.editor;
        },
        focus() {
            this.editor.focus();
        },
        setChanged() {
            this.fixQuotes();
            clearTimeout(this.lastChanged);
            this.lastChanged = setTimeout(() => {
                this.$nuxt.$emit("editorChanged");
                if (this.file.ext === "html") {
                    this.$nuxt.$emit("autoUpdated");
                }
            }, 1000);
            this.$emit("changed");
        },
        copy() {
            const text = this.editor.getValue();
            this.$saveLocal("copiedValue", text);
            this.$copy(text);
        },
        getMode(ext = "html") {
            if (ext === "html") return "htmlmixed";
            if (ext === "js") return "javascript";
            if (ext === "py") return "python";
            if (ext === "txt") return "text/plain";
            return ext;
        },
        updateEditor() {
            const settings = JSON.parse(localStorage.getItem("editorSettings"));
            if (settings && this.allowsettings) {
                this.editor.setOption("autoCloseTags", settings.autoClose);
                this.editor.setOption("autoCloseBrackets", settings.autoClose);
                this.editor.setOption("matchTags", settings.matchTags);
                this.editor.setOption("lineWrapping", this.wrap !== undefined ? this.wrap : settings.lineWrapping);
                this.editor.setOption("theme", settings.theme === "oceanic-next" ? "mbo" : settings.theme);
                this.editor.setOption("spellcheck", settings.spellcheck !== undefined ? settings.spellcheck : false);
            }
        },
        loadFile(file) {
            if (!this.editor) {
                setTimeout(() => {
                    this.loadFile(file);
                }, 100);
                return;
            }
            const mode = this.getMode(file.ext);
            let spaces = 2;
            if (mode === "python") {
                spaces = 4;
            }
            this.editor.setOption("mode", mode);
            this.editor.setOption("indentUnit", spaces);
            this.editor.setValue(file.content || "");
            this.editor.setOption(
                "readOnly",
                !file.id || (file.creator && file.creator.id && this.$store.state.user.id !== file.creator.id)
            );
            this.editor.clearHistory();
            this.file = { ...file };
            this.$nuxt.$emit("fileChange", mode);
        },
        refresh() {
            this.editor.refresh();
        },
        fixQuotes() {
            const cursorPos = this.editor.getCursor();
            const regex = /[‘’“”]/g;
            const val = this.editor.getValue();

            if (val.match(regex)) {
                this.editor.setValue(val.replace(/[“”]/g, '"').replace(/[‘’]/g, "'"));
                this.editor.setCursor(cursorPos);
            }
        },
        runCode() {
            this.$nuxt.$emit("runPreview");
        }
    },
    mounted() {
        const mode = this.mode || this.getMode(this.ext);
        const el = this.$refs.codeMirror;
        const value = this.text || "";
        el.value = value.replace(/&lt/g, "<").replace(/&gt/g, ">");
        var editor = CodeMirror.fromTextArea(el, {
            mode: mode || "htmlmixed",
            lineNumbers: true,
            lineWrapping: true,
            toggleComment: true,
            matchBrackets: true,
            indentWithTabs: true,
            inputStyle: "contenteditable",
            spellcheck: false
        });
        editor.setSize(null, this.resize ? "auto" : null);
        const focusRef = this.focusRef;
        const runCode = this.runCode;
        editor.setOption("extraKeys", {
            "Ctrl-/": function (cm) {
                cm.toggleComment();
            },
            "Cmd-/": function (cm) {
                cm.toggleComment();
            },
            "Ctrl-S": function (cm) {
                // save
            },
            "Cmd-S": function (cm) {
                // save
            },
            "Shift-Esc": function (cm) {
                focusRef("label");
            },
            "Alt-Esc": function (cm) {
                focusRef("exit");
            },
            "Opt-Esc": function (cm) {
                focusRef("exit");
            },
            F6: function (cm) {
                runCode();
            }
        });
        var map = {
            "Ctrl-F": function (cm) {
                cm.execCommand("findPersistent");
            },
            "Cmd-F": function (cm) {
                cm.execCommand("findPersistent");
            }
        };
        editor.addKeyMap(map);
        this.editor = editor;
        this.updateEditor();
        editor.on("copy", (cm, event) => {
            if (!this.allowcopy) {
                event.preventDefault();
                return false;
            }
        });
        editor.on("change", this.setChanged);
        this.$emit("mounted");
        this.$nuxt.$on("updateEditorSettings", this.updateEditor);
    },
    beforeDestroy() {
        this.$nuxt.$off("updateEditorSettings", this.updateEditor);
        this.editor.off("editorChanged", this.setChanged);
    }
};
