
const IMAGE_TYPES = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    svg: "image/svg+xml"
};

const AUDIO_TYPES = {
    wav: "audio/wav",
    ogg: "audio/ogg",
    mp3: "audo/mpeg"
};

export default {
    name: "Item",
    props: {
        item: Object
    },
    data() {
        return {
            copied: false
        };
    },
    computed: {
        url() {
            return `https://library.skillstruck.com/${this.item.id}`;
        },
        type() {
            if (this.item.items) return "folder";
            if (IMAGE_TYPES[this.item.ext]) {
                return "image";
            }
            if (AUDIO_TYPES[this.item.ext]) {
                return "audio";
            }
            return "file";
        }
    },
    methods: {
        async copy() {
            await navigator.clipboard.writeText(this.url).then(() => {
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 400);
            });
        }
    }
};
