
export default {
    data() {
        return {
            feeling: null,
            code_example: "",
            comments: "",
            options: [
                { text: "😊", value: "happy" },
                { text: "😐", value: "neutral" },
                { text: "🙁", value: "unhappy" }
            ]
        };
    },
    methods: {
        send() {
            const data = {
                feeling: this.feeling,
                code: this.code_example,
                comments: this.comments,
                url: this.$route.fullPath,
                lesson_id: this.$store.state.currentLesson.id,
                lesson_name: this.$store.state.currentLesson.name,
                project_id: this.$store.state.currentProject.id,
                project_name: this.$store.state.currentProject.name,
                file_id: localStorage.getItem("workingFile"),
                file_name: localStorage.getItem("workingFileName")
            };
            this.$axios
                .$post("/feedback", { form: data })
                .then(() => {
                    this.$refs.modal.hide();
                    this.feeling = null;
                    this.code_example = "";
                    this.comments = "";
                })
                .catch(err => {
                    console.error(err);
                    alert("Error submitting feedback");
                });
        }
    },
    mounted() {
        this.$nuxt.$on("feedback", this.$refs.modal.show);
    },
    destroyed() {
        this.$nuxt.$off("feedback");
    }
};
