
export default {
    data() {
        return {
            kind: "hex",
            color: "#0f6bff",
            copied: false
        };
    },
    computed: {
        rgb() {
            return this.color
                .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
                .substring(1)
                .match(/.{2}/g)
                .map(x => parseInt(x, 16))
                .join();
        }
    },
    methods: {
        change(color) {
            this.color = color;
            document.getElementById("copy_btn").focus();
        },
        async copy() {
            const text = this.kind === "hex" ? this.color : this.rgb;
            await this.$copy(text);
            this.copied = true;
            setTimeout(() => {
                this.$root.$emit("bv::hide::popover", "colorpicker");
            }, 300);
        }
    }
};
